import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    buttonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: '#424242',
    },
    button: {
        width: '50%',
        height: '50vh',
        borderRadius: 0,
        fontSize: '2rem',
        border: '1px #555 solid',
        color: '#FFF'
    }
}));

const notes = [
    { url: 'https://singui.ca/audio/note/note-N0-O4.mp3', name: 'A4' },
    { url: 'https://singui.ca/audio/note/note-N4-O4.mp3', name: 'C#5' },
    { url: 'https://singui.ca/audio/note/note-N7-O4.mp3', name: 'E5' },
    { url: 'https://singui.ca/audio/note/note-N0-O5.mp3', name: 'A5' }
];

const PitchButtons = (): React.ReactElement => {
    const classes = useStyles();

    // Because we had tons of compatibility issues, I'm doing the raw, gross stuff here to be safe.
    const onClick = (name: string) => {
        notes.forEach(note => {
            const el = document.getElementById(note.name);
            if (!el) return;
            const audioEl = el as HTMLAudioElement;

            audioEl.currentTime = 0;
            console.log(note.name, name);
            if (note.name === name) audioEl.play();
            else if(!audioEl.ended) audioEl.pause();
        });
    };
    return (
        <div className={classes.buttonBox}>
            {notes.map(({ name, url }) => (
                <Button className={classes.button} key={name} onClick={() => onClick(name)}>
                    <audio id={name} src={url} />
                    {name}
                </Button>
            ))}
        </div>
    );
};

export default PitchButtons;
